.accordionContainer{
    justify-content: center;
    margin-top: 2rem;
    
    margin-left: auto;
    margin-right: auto;
    min-width: 500px;
    max-width: 80%;
}

@media (max-width: 600px) {
    .accordionContainer {
        margin-left: auto;
        margin-right: auto;    
        max-width: 100%;
     }
  }