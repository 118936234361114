.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21em;
}
.header-text{  
    display: flex;
    justify-content: center;
    align-items: center; 

    height: 7em;  

    color: #256ce1;
    font-size: calc(10px + 0.95vw);
}

.header-call{  
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 2em;  
    margin-top: 0px;
    padding-bottom: 2em;

    margin-bottom: 3rem;

    color: #256ce1;
    font-size: calc(10px + 0.95vw);
}

.contact-box{
    padding: 1em;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.contact-button-btn {
    border-radius: 4px;
    background: #256ce1;
    padding: 10px 22px;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none; 
    
}

.contact-button-btn:hover {
    transition: all 0.2s ease-in-out;
    background: #5995ea;    
}

.form{
    width: 600px;
    max-width: calc(100% - 20px);
}

.form-control{
    width: 100%;
    margin-bottom: 0.8rem;    

    font-size: 1rem;
    padding: 0.6rem;
    color: black;
    background-color: whitesmoke;
    outline: none;
    border: none;
    border-radius: 4px;

    resize: none;
}
.message-box{
    min-height: 200px;
}

