.custom-shape-divider-bottom-1636412139 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index:-50
}

.custom-shape-divider-bottom-1636412139 svg {
    position: relative;
    display: block;
    width: calc(167% + 1.3px);
    height: 462px;
    transform: rotateY(180deg);
    z-index:-50
}

.custom-shape-divider-bottom-1636412139 .shape-fill {
    fill: #ffff;
    z-index:-50
}
.homeText {
    position: relative;
    align-items: flex-start;
    margin-right: 4.4em;
    margin-top: 60px;    
    flex-wrap: nowrap;
    line-height: 1.3;

    /* Backup font for older browsers */
    font-size: 32px;

    /*font-size: 3.5vw; */
    font-size: calc(6px + 3.5vw);
   
}
.homeTextLooped {    
    color: #256ce1;
}
.ourKittensButton {    
    position: relative;
    align-items: flex-start;
    max-width: fit-content;
    margin-top: 4em;
    flex-wrap: nowrap;

    /* Backup font for older browsers */
    font-size: 16px;    
    
    /*font-size: 0.95vw;*/
    font-size: calc(6px + 0.95vw);

    border-radius: 4px;
    background: #256ce1;
    padding: 10px 22px;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;    
}

.ourKittensButton:hover {
    transition: all 0.2s ease-in-out;
    background: #5995ea;    
}

.homeCat {     
    z-index: -40;
    display: flex;

    width: 1200px;
    height: auto;

    top: 12%;
    left: 18%;
    position: fixed;
    transform: translate(-50%, -50%);    
    transform: scaleX(-1);
}

@media (min-width: 0px) {
    .homeCat {
        width: 425px;
        top: 22%;
        left: 10%;
        position: fixed;
     }
  }
  @media (min-width: 550px) {
    .homeCat {
        width: 550px;
        top: 20%;
        left: 20%;
        position: fixed;
     }
  }
  @media (min-width: 800px) {
    .homeCat {
        width: 750px;
        top: 20%;
        left: 20%;
        position: fixed;
     }
  }
  @media (min-width: 1000px) {
    .homeCat {
        width: 850px;
        top: 25%;
        left: 18%;
        position: fixed;
     }
  }

  @media (min-width: 1250px) {
    .homeCat {
        width: 1100px;
        top: 15%;
        left: 18%;
        position: fixed;
     }
  }
  @media (min-width: 1400px) {
    .homeCat {
        width: 1200px;
        top: 20%;
        left: 28%;
        position: fixed;
     }
  }

.gradient {
    z-index:-60;
    display: flex;

    margin-bottom: 2vh;
    position: fixed; 
    
    /* Fallback parameters*/
    height: 1080px;
    width: 1920px;

    height: 2000em;
    width: 2000em;
    background-image: radial-gradient(closest-corner at 65% 45%,rgba(237,120,145,1), rgba(239,153,167,0.2));

    top: 0%;
    background-position: center 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    
}