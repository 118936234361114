.columns {
    margin: 6rem auto;
    margin-top: 1rem;
    width: min(90%, 75rem);
    display: grid;
    grid-auto-flow: row;
    gap: 2em;   
  }
  
  @media (min-width: 50em) {
    .columns,.columns-2-2 {
      grid-auto-flow: column;
      margin: 8rem auto;
      
      /* didn't mention this during the video
         but it ensures equal columns  */
      /*     grid-auto-columns: 1fr; */
     }
  }

  @media (min-width: 72em) {
    .columns-2-1 {
      grid-auto-flow: column;
      margin: 10rem auto;
      
      /* didn't mention this during the video
         but it ensures equal columns  */
      /*     grid-auto-columns: 1fr; */
     }
  }
 

  .columns-2-1 {
    margin: 6rem auto;
    display: grid;
    justify-content: center;     
    gap: 2em;
  }

  

  .columns-2-2 {
    margin: 8rem auto;
    width: min(90%, 75rem);
    display: grid;
    grid-auto-flow: row;    
    gap: 2em;
    
    grid-template-columns: 60% 40%;
  }

  .spacer-line {
      justify-content: center;
      display: flex;
      color: white;
      font-size: larger;

    background: #256ce1;
    padding: 20px;
  }

  .columnHeader {
      color: darkslategray;
  }

  h2 {
      padding-bottom: 6px;
  }

  #grad1 {
    height: 600px;
    width: 200em;
    background-image: linear-gradient(to top, rgba(237,120,145,0.7), rgba(239,153,167,0));

    z-index:-60;
    display: flex;
    position: fixed;
    top: 0%;
    

    margin-bottom: 2vh;
  }